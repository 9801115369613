import * as React from 'react'
import styled from '@emotion/styled'

import Page from '../layouts/Page'

import wired from '../images/wired.svg'
import vogue from '../images/vogue.png'
import goeuro from '../images/goeuro.png'
import skycatch from '../images/skycatch.png'
import TheNewYorker from '../images/TheNewYorker.svg'

const Center = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 90px;
  font-weight: 200;
  font-family: 'Alegreya Sans SC', cursive;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 90px;
  }
`

const Subheading = styled.h2`
  margin: 0;
  font-size: 21px;
  font-weight: 300;
  font-family: 'Lato', sans;
  text-align: center;
  text-transform: uppercase;

`

const Text = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  text-align: center;

  margin-bottom: 100px;
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

const CustomerLogo = styled.img`
  // flex: 1;
  height: 25px;
  margin: 20px;
  filter: grayscale(100%);
`

const SectionHeading = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lato', sans;
  text-align: center;
  text-transform: uppercase;

  margin-top: 80px;
  margin-bottom: 3px;
`

const Button = styled.a`
  display: block;
  color: black;
  text-decoration: none;
  margin: 80px auto 0px;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Lato', sans;
  text-align: center;
  padding: 10px 18px;
  border: 1px solid #BBBBBB;
  border-radius: 10px;

  :hover {
     border: 1px solid grey;
  }
`

const Link = styled.a`
  display: block;
  color: black;
  text-decoration: none;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Lato', sans;
  text-align: center;
`

const IndexPage = () => (
  <Page>
    <Center>
      <Heading>Wayful</Heading>
      <Subheading>IT & Business Consulting</Subheading>
      
      <SectionHeading>Contact</SectionHeading>
      <Link href='mailto:hello@wayful.pt'>hello@wayful.pt</Link>

      <SectionHeading>Happy Clients</SectionHeading>
      <Flex>
        <CustomerLogo src={wired} />
        <CustomerLogo src={TheNewYorker} />
        <CustomerLogo src={vogue} />
      </Flex>
     <Flex>
        <CustomerLogo src={skycatch} />
        <CustomerLogo src={goeuro} />
      </Flex>

    </Center>
  </Page>
)

export default IndexPage
